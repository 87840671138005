import React from "react";
import LightBox from "./lightbox";

export default class Proyecto extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            lightBoxOpened: false
        }
    }

    toggleLightBox(){
        this.setState({
            lightBoxOpened: !this.state.lightBoxOpened
        });
    }

    render(){
        return(
            <div className="proyecto wow fadeInUp" data-wow-delay="0.05s" style={this.props.addstyle}>
                <img className="proyecto-image" src={this.props.image} alt=""/>
                <div className="proyecto-content">
                    <a href="#!" className="proyecto-info-btn" onClick={this.toggleLightBox.bind(this)}>
                        <i className="fas fa-search-plus"></i>
                    </a>
                    <LightBox 
                        image={this.props.image} 
                        opened={this.state.lightBoxOpened}
                        closeAction={this.toggleLightBox.bind(this)}/>
                    {this.props.children}
                </div>
            </div>
        );
    }
}