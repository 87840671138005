import React from "react";

export default function LightBox(props){
    return(
        <div className={props.opened ? "lightbox opened" : "lightbox"}>
            <img className="lightbox-image" src={props.image} alt=""/>
            <a className="lightbox-close" href="#!" onClick={props.closeAction}>
                <i className="far fa-times-circle fa-2x"></i>
            </a>
        </div>
    );

}