import React from "react";
import { render } from "react-dom";
import Proyecto from "./components/proyecto";
import imageSenalizacionVial from "../img/proyectos/senalizacion_vial.jpg";
import pimage1 from "../img/proyectos/boveda-1_2x.jpg";
import pimage2 from "../img/proyectos/Tubo-de-Concreto-Villa-Nueva_2x.jpg";
import pimage3 from "../img/proyectos/control-suelos_2x.jpg";
import pimage4 from "../img/proyectos/senalizacionvial-franjatrans-norte_2x.jpg";
import pimage5 from "../img/proyectos/senalizacionvial-ftnorte4_2x.jpg";
import pimage6 from "../img/proyectos/senalizacionvial-ftnorte5_2x.jpg";
import pimage7 from "../img/proyectos/Tubo-de-Concreto-Villa-Nueva2_2x.jpg";
import pimage8 from "../img/proyectos/senalizacionvial-ftnorte7_2x.jpg";
import pimage9 from "../img/proyectos/gaviones3_2x.jpg";
import pimage10 from "../img/proyectos/tuberiaconcreto-ptoQuetzal1_2x.jpg";
import pimage11 from "../img/proyectos/gaviones2-1_2x.jpg";
import pimage12 from "../img/proyectos/tuberiaconcreto-antiguaEscuintla_2x.jpg";
import pimage13 from "../img/proyectos/salcaja-metal_2x.jpg";
import pimage14 from "../img/proyectos/geotextil-gaviones_2x.jpg";
import pimage15 from "../img/proyectos/tuberiaconcreto-ptoQuetzal_2x.jpg";
import pimage16 from "../img/proyectos/tuboconcreto-cocales_2x.jpg";
import pimage17 from "../img/proyectos/tubodeconcreto-atlantico_2x.jpg";

class Proyectos extends React.Component {

    render(){
        return(
            <div className="proyectos">
                <Proyecto image={imageSenalizacionVial}>
                    <h4>Defensa Metálica</h4>
                </Proyecto>
                <Proyecto image={pimage1}>
                    <h4>Bóvedas Vehiculares</h4>
                </Proyecto>
                <Proyecto image={pimage3}>
                   <h4>Mantos Anti erosión</h4>
                </Proyecto>
                <Proyecto image={pimage4}>
                    <h4>Señalización vial</h4>
                    <p>Franja transversal del norte</p>
                </Proyecto>
                <Proyecto image={pimage5} addstyle={{gridRowEnd: "span 2"}}>
                    <h4>Señalización vial</h4>
                    <p>Franja transversal del norte</p>
                </Proyecto>
                <Proyecto image={pimage6}>
                    <h4>Defensa Metálica</h4>
                    <p>Franja transversal del norte</p>
                </Proyecto>
                <Proyecto image={pimage7}>
                    <h4>Señalización vial</h4>
                    <p>Franja transversal del norte</p>
                </Proyecto>
                <Proyecto image={pimage8}>
                    <h4>Colchones</h4>
                    <p>Franja transversal del norte</p>
                </Proyecto>
                <Proyecto image={pimage9}>
                   {/* <h4>Gaviones</h4> */}
                </Proyecto>
                <Proyecto image={pimage10} addstyle={{gridRowEnd: "span 2"}}>
                    <h4>Tubería de Concreto</h4>
                    <p>Puerto Quetzal</p>
                </Proyecto>
                <Proyecto image={pimage11}>
                   <h4>Gaviones</h4>
                </Proyecto>
                <Proyecto image={pimage12}>
                    <h4>Tubería de Concreto</h4>
                    <p>Antígua-Esquintla</p>
                </Proyecto>
                <Proyecto image={pimage13}>
                    <h4>Tubería Metálica Corrugada</h4>
                    <p>Salcajá</p>
                </Proyecto>
                <Proyecto image={pimage14}>
                    <h4>Geotextil-Gaviones</h4>
                </Proyecto>
                <Proyecto image={pimage15}>
                    <h4>Tubería de Concreto</h4>
                    <p>Puerto Quetzal</p>
                </Proyecto>
                <Proyecto image={pimage16}>
                    <h4>Tubería de Concreto</h4>
                    <p>Cocales</p>
                </Proyecto>
                <Proyecto image={pimage17}>
                    <h4>Tubería de Concreto</h4>
                    <p>Atlántico</p>
                </Proyecto>
                <Proyecto image={pimage2}>
                    <h4>Tubo de concreto</h4>
                    <p>Villa Nueva</p>
                </Proyecto>
                {/* <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/boveda-1_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Bóveda</h4>
                        <p>Franja transversal del norte</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/Tubo-de-Concreto-Villa-Nueva_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Tubo de concreto</h4>
                        <p>Villa Nueva</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/control-suelos_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Control de Suelos</h4>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/senalizacionvial-franjatrans-norte_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Señalización vial</h4>
                        <p>Franja transversal del norte</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" style="grid-row-end: span 2;" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/senalizacionvial-ftnorte4_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Señalización vial</h4>
                        <p>Franja transversal del norte</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/senalizacionvial-ftnorte5_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Señalización vial</h4>
                        <p>Franja transversal del norte</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/Tubo-de-Concreto-Villa-Nueva2_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Señalización vial</h4>
                        <p>Franja transversal del norte</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/senalizacionvial-ftnorte7_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Señalización vial</h4>
                        <p>Franja transversal del norte</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/gaviones3_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Gaviones</h4>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" style="grid-row-end: span 2;" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/tuberiaconcreto-ptoQuetzal1_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Tubería de Concreto</h4>
                        <p>Puerto Quetzal</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/gaviones2-1_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Gaviones</h4>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/tuberiaconcreto-antiguaEscuintla_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Tubería de Concreto</h4>
                        <p>Antígua-Esquintla</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp">
                    <img className="proyecto-image" src="img/proyectos/salcaja-metal_2x.jpg" alt="" data-wow-delay="0.05s"/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Tubería de Concreto</h4>
                        <p>Salcajá</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/geotextil-gaviones_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Geotextil-Gaviones</h4>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/tuberiaconcreto-ptoQuetzal_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Tubería de Concreto</h4>
                        <p>Puerto Quetzal</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/tuboconcreto-cocales_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Tubería de Concreto</h4>
                        <p>Cocales</p>
                    </div>
                </div>
                <div className="proyecto wow fadeInUp" data-wow-delay="0.05s">
                    <img className="proyecto-image" src="img/proyectos/tubodeconcreto-atlantico_2x.jpg" alt=""/>
                    <div className="proyecto-content">
                        <a href="#!" className="proyecto-info-btn"><i className="fas fa-info"></i></a>
                        <h4>Tubería de Concreto</h4>
                        <p>Atlántico</p>
                    </div>
                </div> */}
            </div>
        );
    }

}

render(<Proyectos/>, document.getElementById("proyectos-wrapper"));